import Chevron from 'assets/svg/chevron/chevron.svg'
import Flex from 'common/components/Flex'
import Image from 'common/components/Image'
import Skeleton from 'common/components/Skeleton'
import Typography from 'common/components/Typography'
import theme from 'common/theme'
import textToSnake from 'lib/transform/text-to-snake'
import Link from 'next/link'
import { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex: 1;
  height: 365px;
  width: 882px;
  background-color: ${theme.colors.white};
  border-radius: ${theme.radius.xl};
  background-clip: content-box;
`

const MenuItem = styled.div`
  display: flex;
  height: 48px;
  box-sizing: border-box;
  padding: 8px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2px;
  border-radius: ${theme.radius.base};
  :last-child {
    margin-bottom: unset;
  }
  svg {
    display: none;
  }

  ${({ active }) =>
    active &&
    `
    background-color: ${theme.colors.grayLight};
    cursor: pointer;
    svg {
      display: block;
    }
  `}
`

const WrapperMenuItem = styled.div`
  flex-direction: column;
  display: flex;
  width: 260px;
  box-sizing: border-box;
  padding: 32px 24px;
  background-color: ${theme.colors.grayLighter};
  height: 100%;
  gap: 8px;
  border-top-left-radius: ${theme.radius.xl};
  border-bottom-left-radius: ${theme.radius.xl};
`

const WrapperIcon = styled.div`
  height: fit-content;
  width: 32px;
`

const ChevronRight = styled(Chevron)`
  transform: rotate(270deg);
`

const WrapperContent = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  padding: 32px 32px;
  background-color: ${theme.colors.white};
  border-top-right-radius: ${theme.radius.xl};
  border-bottom-right-radius: ${theme.radius.xl};
`

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  flex-grow: 1;
  overflow-y: scroll;
  grid-column-gap: 32px;
  grid-row-gap: 24px;
  ::-webkit-scrollbar {
    display: none;
  }
`

const WrapperExtra = styled.div`
  display: flex;
  flex-direction: column;
  height: 90px;
  box-sizing: border-box;
  padding-top: 16px;
  gap: 8px;
  border-top: 1px solid ${theme.colors.grayLight};
`

const ItemSolution = styled.div`
  display: flex;
  gap: 8px;
  height: fit-content;
  padding: 10px 0;
  cursor: pointer;

  label {
    cursor: pointer;
  }
`

const TextButton = styled.a`
  ${theme.typography.bcaption};
  color: ${theme.colors.redOrange};
`

const ExtraItem = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: fit-content;
  cursor: pointer;
`

interface ItemSolution {
  iconUrl: string
  title: string
  description: string
  redirectText: string
  redirectUrl: string
}

interface IExtra {
  bottomIconUrl: string
  bottomTitle: string
  bottomDescription: string
  bottomRedirectUrl: string
}

export interface ISolutionMenu extends IExtra {
  iconUrl: string
  title: string
  items: ItemSolution[]
}

interface IMegaMenu {
  items: ISolutionMenu[]
  defaultKeys?: string
  onMouseLeave?: (event: MouseEvent) => void
  isLoading?: boolean
}

const STYLED_ICON = {
  width: '32px',
  height: '32px',
  objectFit: 'cover',
}

function RenderSolution({
  items,
  setOpenKeys,
  openKeys,
}): JSX.Element {
  return (
    <WrapperMenuItem>
      {items?.map((item) => {
        const key = textToSnake(item.title)
        const onMouseEnter = () => {
          setOpenKeys(key)
        }

        return (
          <MenuItem
            data-testid={`menu-solution-${key}`}
            key={key}
            active={key === openKeys}
            onMouseEnter={onMouseEnter}
          >
            <Flex gap="8px" alignItems="center">
              <WrapperIcon>
                <Image {...STYLED_ICON} src={item.iconUrl} />
              </WrapperIcon>
              <Typography type="bsubtitle">{item.title}</Typography>
            </Flex>
            <ChevronRight />
          </MenuItem>
        )
      })}
    </WrapperMenuItem>
  )
}

function RenderContent({
  items,
}: {
  items: ItemSolution[]
}): JSX.Element {
  return (
    <Content>
      {items?.map((solution, index) => {
        const key = String(index)
        const {
          iconUrl,
          title,
          description,
          redirectText,
          redirectUrl,
        } = solution
        return (
          <Link key={key} href={redirectUrl}>
            <ItemSolution data-testid={`submenu-solution-${key}`}>
              <WrapperIcon>
                <Image {...STYLED_ICON} src={iconUrl} />
              </WrapperIcon>
              <Flex gap="4px" flexDirection="column">
                <Typography type="bsubtitle">{title}</Typography>
                <Typography type="rlabel">{description}</Typography>
                <TextButton href={redirectUrl}>
                  {redirectText}
                </TextButton>
              </Flex>
            </ItemSolution>
          </Link>
        )
      })}
    </Content>
  )
}

function RenderExtra({
  bottomIconUrl,
  bottomDescription,
  bottomRedirectUrl,
  bottomTitle,
}: IExtra): JSX.Element {
  return (
    <WrapperExtra data-testid="extra-megamenu">
      <Link href={bottomRedirectUrl}>
        <ExtraItem alignItems="center" gap="12px">
          <Image {...STYLED_ICON} src={bottomIconUrl} />
          <Typography type="bsubtitle">{bottomTitle}</Typography>
          <ChevronRight />
        </ExtraItem>
      </Link>
      <Typography type="rlabel" color="grayDark">
        {bottomDescription}
      </Typography>
    </WrapperExtra>
  )
}

function MegaMenu(props: IMegaMenu): JSX.Element {
  const { items, defaultKeys, onMouseLeave, isLoading } = props
  const defaultOpen = defaultKeys ?? textToSnake(items[0]?.title)
  const [openKeys, setOpenKeys] = useState<string>(defaultOpen)

  const itemSolution = useMemo(
    () => items?.find((item) => textToSnake(item.title) === openKeys),
    [openKeys, items],
  )

  const handleOpenKeys = useCallback((keys) => {
    setOpenKeys(keys)
  }, [])

  return (
    <Container
      data-testid="container-megamenu"
      onMouseLeave={onMouseLeave}
    >
      {isLoading ? (
        <Flex padding="20px" flexGrow="1">
          <Skeleton height="100%" />
        </Flex>
      ) : (
        <>
          <RenderSolution
            items={items}
            setOpenKeys={handleOpenKeys}
            openKeys={openKeys}
          />
          <WrapperContent>
            <RenderContent {...itemSolution} />
            <RenderExtra {...itemSolution} />
          </WrapperContent>
        </>
      )}
    </Container>
  )
}

export default MegaMenu
