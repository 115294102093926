import config from 'config/auth'
import checkToken from 'lib/auth/check-token'
import redirect from 'lib/common/redirect'

/**
 * Checks the authorization status of a client by verifying the presence of access and refresh tokens.
 * If either the access token or the refresh token is present, the client is considered authorized.
 * @returns {boolean} - A boolean value indicating the authorization status of the client.
 */
export function isClientAuthorized(): boolean {
  const accessToken = checkToken(config.accessTokenName)
  const refreshToken = checkToken(config.refreshTokenName)

  return !!accessToken || !!refreshToken
}

/**
 *
 * Authorizes a client by checking for the presence of access and refresh tokens.
 * If both tokens are missing, it redirects the client to the login page.
 * @param referrer (optional) - The URL or referrer from which the authorization request originates.
 * @returns void
 *
 */

function authorizeClient(referrer?: string): void {
  if (!isClientAuthorized()) {
    redirect('/login', referrer)
  }
}

export default authorizeClient
