import Checked from 'assets/svg/check/check-green-round.svg'
import Accordion from 'common/components/Accordion'
import Flex from 'common/components/Flex'
import Typography from 'common/components/Typography'
import { LANG_COOKIE_NAME } from 'common/constants'
import LOCALE_OPTIONS from 'common/constants/locale-options'
import theme from 'common/theme'
import { setCookie } from 'lib/cookie'
import MediaHelper from 'lib/media-helper'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface ILanguageAccordionProps {
  initialLang?: string
  onChange?: Function
}

const Wrapper = styled.div`
  width: unset;
  font-weight: bold;
  color: ${theme.colors.black};
  margin: 16px 0;
  font-size: 14px;
  ${MediaHelper('desktop')`width: 100%;`}
`

const Options = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
const OptionItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  &:not(:last-child) {
    border-bottom: 1px solid ${theme.colors.grayLighter};
    padding-bottom: 8px;
  }
`

function LanguageAccordion({
  initialLang = 'id',
  onChange = () => null,
}: ILanguageAccordionProps): JSX.Element {
  const [selectedLang, setSelectedLang] = useState(initialLang)
  const { i18n, t } = useTranslation()
  const handleClickLang = useCallback(
    (newLang) => {
      setSelectedLang(newLang)
      i18n.changeLanguage(newLang)
      setCookie(LANG_COOKIE_NAME, newLang)
      onChange()
    },
    [i18n, onChange],
  )
  return (
    <Wrapper>
      <Accordion
        paddingSummary="0"
        summaryStyle={{
          padding: '0px',
          height: '16px',
          width: '100%',
          'min-height': 'unset',
        }}
        styleDetails={{
          padding: '20px 0 0 0',
        }}
        noStyleWrapper
        summary={t('LANGUAGE', { ns: 'common' })}
        borderBottomExpanded="0"
      >
        <Options>
          {LOCALE_OPTIONS.map((locale) => {
            const handleClick = () => {
              handleClickLang(locale.value)
            }

            const isActive = locale.value === selectedLang

            return (
              <OptionItem
                key={locale.value}
                active={isActive}
                onClick={handleClick}
              >
                <Flex alignItems="center" gap="5px">
                  {locale.icon}
                  <Typography
                    fontWeight={isActive ? 'bold' : 'normal'}
                  >
                    {locale.label}
                  </Typography>
                </Flex>
                {isActive && <Checked />}
              </OptionItem>
            )
          })}
        </Options>
      </Accordion>
    </Wrapper>
  )
}

export default LanguageAccordion
