import theme from 'common/theme'
import MediaHelper from 'lib/media-helper'
import React from 'react'
import styled from 'styled-components'

const StyledBurger = styled.button`
  display: none;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  width: 1.563rem;
  height: 1.563rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: ${theme.zIndex.popover};
  visibility: hidden;

  &:focus {
    outline: none;
  }

  div {
    width: 1.563rem;
    height: 0.125rem;
    background: ${({ iconLight, isNotTransparent }) => {
      if (isNotTransparent) {
        return theme.colors.grayDark
      }
      return iconLight ? theme.colors.white : theme.colors.grayDark
    }};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    :first-child {
      transform: ${({ open }) =>
        open ? 'rotate(45deg)' : 'rotate(0)'};
    }
    :nth-child(2) {
      opacity: ${({ open }) => (open ? '0' : '1')};
      transform: ${({ open }) =>
        open ? 'translateX(20px)' : 'translate(0)'};
    }
    :nth-child(3) {
      transform: ${({ open }) =>
        open ? 'rotate(-45deg)' : 'rotate(0)'};
    }

    ${({ open }) =>
      open && `background: ${theme.colors.black} !important;`};
  }

  ${MediaHelper('laptop')`visibility: visible; display: flex`}
`

interface IBurger {
  open: boolean
  iconLight: boolean
  setOpen?: (boolean) => void
  isNotTransparent?: boolean
}

const Burger = ({
  open,
  setOpen,
  iconLight,
  isNotTransparent,
}: IBurger): JSX.Element => {
  return (
    <StyledBurger
      iconLight={iconLight}
      open={open}
      onClick={setOpen}
      isNotTransparent={isNotTransparent}
    >
      <div />
      <div />
      <div />
    </StyledBurger>
  )
}

export default Burger
