import ArrowIcon from 'assets/svg/arrow/arrow.svg'
import Flex from 'common/components/Flex'
import Skeleton from 'common/components/Skeleton'
import theme from 'common/theme'
import { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'

const Wrapper = styled.div`
  ${({ noStyleWrapper }) =>
    !noStyleWrapper &&
    `
    background-color: ${theme.colors.white};
    border-radius: 6px;
    box-shadow: 0 2px 5px 0 rgba(61, 61, 61, 0.12);
  `}
`

const Summary = styled.div`
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  min-height: ${(props) => props.minHeight};
  padding: ${(props) => props.padding};
  user-select: none;
  ${({ expanded, bgColorSummaryExpanded, borderBottomExpanded }) =>
    expanded &&
    `
      border-bottom: ${borderBottomExpanded} solid ${theme.colors.grayLighter};
      background-color: ${bgColorSummaryExpanded};
  `}
  ${({ disabled }) =>
    disabled &&
    `
      background-color: ${theme.colors.grayLighter};
      pointer-events: none; 
      cursor: default;
  `}
  ${({ customStyle }) => customStyle};
`

const SummaryContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${({ disabled }) => disabled && `opacity: 0.3;`}
`

const SummaryWrapperIcon = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
  transform: ${({ expanded }) =>
    expanded ? 'rotate(0deg)' : 'rotate(180deg)'};
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  svg {
    path {
      fill: ${theme.colors.gray};
    }
  }
`

const fade = keyframes`
  from {
    opacity: .4
  }
  to {
    opacity: 1
  }
`

const Details = styled.div`
  animation: ${fade} 500ms;
  padding: ${({ paddingDetails }) => paddingDetails};
  ${({ customStyle }) => customStyle};
`

const StyledFlex = styled(Flex)`
  width: ${({ width }) => width};
  align-items: center;
`

type TElement = JSX.Element | JSX.Element[] | string

interface IAccordion {
  prependSummary?: TElement
  summary: TElement
  subSummary?: TElement
  paddingSummary?: string
  paddingDetails?: string
  minHeight?: string
  disabled?: boolean
  noStyleWrapper?: boolean
  expanded?: boolean
  expandIcon?: JSX.Element
  bgColorSummaryExpanded?: string
  children: TElement
  isLoading?: boolean
  onChange?: Function
  styleDetails?: Object
  borderBottomExpanded?: string
  summaryStyle?: Object
  summaryHeaderWidth?: string
}

function Accordion({
  noStyleWrapper = false,
  summary,
  subSummary = '',
  paddingSummary = '20px',
  paddingDetails = '20px',
  minHeight = '54px',
  disabled = false,
  expanded: expandedProp,
  expandIcon = <ArrowIcon />,
  bgColorSummaryExpanded = theme.colors.white,
  children,
  isLoading = false,
  onChange = null,
  prependSummary,
  styleDetails,
  borderBottomExpanded = '1px',
  summaryStyle,
  summaryHeaderWidth,
}: IAccordion): JSX.Element {
  const [expanded, setExpanded] = useState<boolean>(
    expandedProp || false,
  )

  const handleExpand = () => {
    if (!isLoading) {
      if (expandedProp === undefined) setExpanded(!expanded)
      if (onChange) onChange(!expanded)
    }
  }

  useEffect(() => {
    setExpanded(expandedProp)
  }, [expandedProp])

  return (
    <Wrapper noStyleWrapper={noStyleWrapper}>
      <Summary
        disabled={disabled}
        expanded={expanded}
        bgColorSummaryExpanded={bgColorSummaryExpanded}
        padding={paddingSummary}
        borderBottomExpanded={borderBottomExpanded}
        customStyle={summaryStyle}
        minHeight={minHeight}
      >
        <Flex alignItems="center">
          <div>{prependSummary}</div>
          <StyledFlex
            alignItems="center"
            data-testid="accordion-summary-test"
            onClick={handleExpand}
            width={summaryHeaderWidth}
          >
            <SummaryContent disabled={disabled}>
              {isLoading ? (
                <Skeleton
                  height="25px"
                  width="156px"
                  borderRadius="6px"
                />
              ) : (
                summary
              )}
            </SummaryContent>
            <SummaryWrapperIcon expanded={expanded}>
              {isLoading ? (
                <Skeleton
                  height="25px"
                  width="95px"
                  borderRadius="6px"
                />
              ) : (
                expandIcon
              )}
            </SummaryWrapperIcon>
          </StyledFlex>
        </Flex>
        {expanded ? subSummary : null}
      </Summary>
      {expanded && (
        <Details
          data-testid="accordion-details-test"
          paddingDetails={paddingDetails}
          customStyle={styleDetails}
        >
          {children}
        </Details>
      )}
    </Wrapper>
  )
}

export default Accordion
