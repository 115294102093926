import LogoCustom from 'assets/svg/logo-ralali-custom/logo-ralali-custom-footer.svg'
import LogoHeaderBusinessSolutionRamadhan from 'assets/svg/logo-ralali-default/logo-ralali-default.svg'
import Logo from 'assets/svg/logo-ralali-default/logo-ralali-default.svg'
import { ACTIVE_THEME_NAME } from 'common/constants'

function BusinessSolustionHeaderLogo(): JSX.Element {
  if (ACTIVE_THEME_NAME === 'anniversary')
    return <LogoCustom data-testid="business-header-logo-custom" />
  if (ACTIVE_THEME_NAME === 'ramadhan')
    return (
      <LogoHeaderBusinessSolutionRamadhan data-testid="business-header-logo-ramadhan" />
    )
  return <Logo data-testid="business-header-logo-default" />
}

export default BusinessSolustionHeaderLogo
