import FlagEN from 'assets/svg/country-flag/flag-en.svg'
import FlagID from 'assets/svg/country-flag/flag-id.svg'

export default [
  {
    icon: <FlagEN />,
    label: 'EN',
    value: 'en',
  },
  {
    icon: <FlagID />,
    label: 'ID',
    value: 'id',
  },
]
