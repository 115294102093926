import Flex from 'common/components/Flex'
import Image from 'common/components/Image'
import Skeleton from 'common/components/Skeleton'
import theme from 'common/theme'
import cdn from 'lib/cdn'
import MediaHelper from 'lib/media-helper'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const ProfileWrapper = styled.div`
  display: grid;
  grid-template-columns: 24px 1fr;
  grid-gap: 8px;
  color: ${({ color }) => color};
  width: ${({ width }) => width};
`

const NameWrapper = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
`

const Link = styled.a`
  ${() => ({ ...theme.typography.bh6 })};
  color: ${({ color }) => color} !important;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: ${({ padding }) => padding};
  height: ${({ height }) => height};
  border-radius: ${({ borderRadius }) => borderRadius};

  ${MediaHelper(
    'laptop',
  )`font-size: ${theme.typography.bsubtitle.fontSize};`}
`

const StyledFlex = styled(Flex)`
  height: 100%;
`

interface IAuthButtonProps {
  isLogin: boolean
  isLoading?: boolean
  name: string
  image: string
  color?: string
  width?: string
}

function AuthButton({
  isLogin,
  isLoading,
  name,
  image,
  color,
  width = '120px',
}: IAuthButtonProps): JSX.Element {
  const { t } = useTranslation()
  if (!isLogin) {
    return (
      <Link
        href="/login"
        padding="0 27px"
        backgroundColor={theme.colors.redOrange}
        color={theme.colors.white}
        borderRadius={theme.radius.lg}
        height="48px"
      >
        <StyledFlex alignItems="center">
          {t('LOGIN_REGISTER', { ns: 'common' })}
        </StyledFlex>
      </Link>
    )
  }

  if (isLogin && isLoading) {
    return (
      <Flex gap="8px">
        <Skeleton height="24px" width="24px" borderRadius="50%" />
        <Skeleton height="24px" width="96px" />
      </Flex>
    )
  }

  return (
    <Link color={color} href="/customer/profile">
      <ProfileWrapper color={color} width={width}>
        <Image
          src={cdn({
            path: `/assets/img/profile/${image}`,
          })}
          objectFit="cover"
          height="24px"
          width="24px"
          radius={theme.radius.xl}
        />
        <NameWrapper>{name}</NameWrapper>
      </ProfileWrapper>
    </Link>
  )
}

export default AuthButton
