import Dropdown from 'common/components/Dropdown'
import { LANG_COOKIE_NAME } from 'common/constants'
import LOCALE_OPTIONS from 'common/constants/locale-options'
import theme from 'common/theme'
import { setCookie } from 'lib/cookie'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`

const styleContainerList = () => css`
  border-radius: ${theme.radius.base};
`

interface ILanguageDropdownProps {
  initialLang?: string
  color?: string
  onChange?: Function
}

function LanguageDropdown({
  initialLang = 'id',
  color,
  onChange = () => null,
}: ILanguageDropdownProps): JSX.Element {
  const { i18n } = useTranslation()
  const onChangeLang = useCallback(
    (newLang) => {
      setCookie(LANG_COOKIE_NAME, newLang)
      i18n.changeLanguage(newLang)
      onChange()
    },
    [i18n, onChange],
  )

  return (
    <Dropdown
      styleContainerList={styleContainerList}
      width="120px"
      backgroundColor="transparent"
      bordered={false}
      icon={{
        color,
      }}
      color={color}
      value={initialLang}
      onChange={onChangeLang}
      fontWeight="bold"
    >
      {LOCALE_OPTIONS.map((locale) => (
        <option value={locale.value} key={locale.value}>
          <OptionWrapper>
            {locale.icon}
            {locale.label}
          </OptionWrapper>
        </option>
      ))}
    </Dropdown>
  )
}

export default LanguageDropdown
