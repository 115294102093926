import Arrow from 'assets/svg/arrow-right/icon_arrow-right.svg'
import Chevron from 'assets/svg/chevron/chevron.svg'
import RalaliIcon from 'assets/svg/logo-ralali-icon/logo-ralali-icon.svg'
import Accordion from 'common/components/Accordion'
import Burger from 'common/components/Burger'
import Flex from 'common/components/Flex'
import BusinessSolustionHeaderLogo from 'common/components/Header/BusinessSolutionHeader/BusinessSolutionHeaderLogo'
import Image from 'common/components/Image'
import LanguageAccordion from 'common/components/LanguageAccordion'
import LanguageDropdown from 'common/components/LanguageDropdown'
import MegaMenu, { ISolutionMenu } from 'common/components/MegaMenu'
import Overlay from 'common/components/Overlay'
import Typography from 'common/components/Typography'
import { WEB_DESKTOP_RALALI_URL } from 'common/constants'
import theme from 'common/theme'
import { isClientAuthorized } from 'lib/auth/authorize-client'
import eventTracking from 'lib/event-tracking'
import MediaHelper from 'lib/media-helper'
import trackPosthog from 'lib/posthog/tracker'
import transformData from 'lib/transform/array-to-camel-case'
import textToSnake from 'lib/transform/text-to-snake'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useRouter } from 'next/router'
import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import AuthButton from 'services/Auth/widgets/AuthButton'
import { useStoreState } from 'stores/hooks'
import styled from 'styled-components'

const MegaMenuComp = dynamic(
  () => import('common/components/MegaMenu'),
  {
    loading: () => MegaMenu({ isLoading: true, items: [] }),
  },
)

const Header = styled.div<{
  backgroundColor: string
  boxShadow: string
  position: string
}>`
  position: ${({ position }) => position};
  right: ${({ position }) => position === 'fixed' && 0};
  left: ${({ position }) => position === 'fixed' && 0};
  z-index: ${({ position }) => position === 'fixed' && 1030};
  display: flex;
  justify-content: center;
  height: 90px;
  align-items: center;
  top: 0;
  transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
  background-color: ${({ backgroundColor }) => backgroundColor};
  box-shadow: ${({ boxShadow }) => boxShadow};
  z-index: ${theme.zIndex.header};
  width: 100%;
  overflow-x: clip;
`

const Container = styled.div<{
  justifyContent: string
  color?: string
}>`
  width: 1180px;
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  color: ${({ color }) => color};
  align-items: center;
  ${MediaHelper('laptop')`width: 100%; padding: 15px;`};
  ${({ styleContainer }) => styleContainer}
`

const StyledLink = styled.a<{ active?: boolean; color?: string }>`
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: ${theme.typography.bh6.fontSize};
  font-weight: ${theme.typography.bh6.fontWeight};
  font-stretch: ${theme.typography.bh6.fontStretch};
  font-style: ${theme.typography.bh6.fontStyle};
  line-height: 30px;
  letter-spacing: ${theme.typography.bh6.letterSpacing};
  border-bottom: ${({ active }) =>
    `solid 2px ${active ? theme.colors.orange : 'transparent'}`};
  color: ${({ active }) => active && theme.colors.orange} !important;
  text-decoration: none;
  width: fit-content;
  color: ${({ color }) => color};
  cursor: pointer;
  z-index: 10;

  &:hover {
    border-bottom: solid 2px ${theme.colors.orange};
  }

  ${MediaHelper(
    'laptop',
  )`font-size: ${theme.typography.bsubtitle.fontSize};line-height: 14px;`}
`

const WrapperLogo = styled.a<{
  openMenu?: boolean
  logoLight?: boolean
}>`
  display: block;
  z-index: 100;
  line-height: 30px;
  svg {
    path {
      fill: ${({ logoLight, openMenu, isNotTransparent }) => {
        if (isNotTransparent) {
          return ''
        }
        if (openMenu) return ''
        if (logoLight) return theme.colors.white
        return ''
      }};
    }
  }
`

const Menu = styled.div<{
  color: string
  width: string
}>`
  display: flex;
  justify-content: space-between;
  width: ${({ width }) => width};
  align-items: center;
  visibility: visible;
  gap: 25px;

  ul > a {
    color: ${({ color }) => color};
    text-decoration: none;
    cursor: pointer;

    &.active {
      padding: 30px 0px;
      border-bottom: 3px solid ${theme.colors.orange};
    }
  }

  ${MediaHelper('laptop')`display: none;`}
`

const WrapperMenu = styled.ul<{ vertical?: boolean }>`
  display: flex;
  flex-direction: ${({ vertical }) => (vertical ? 'column' : 'row')};
  align-items: ${({ vertical }) =>
    vertical ? 'flex-start' : 'center'};
  justify-content: center;
  padding-left: 0;
  list-style: none;
  gap: 5px;
  /* margin-right: ${({ vertical }) => (vertical ? '0' : '30px')}; */
  ${({ vertical }) => vertical && `width: 100%`}
`

const MenuMobile = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  position: fixed;
  top: 0;
  left: 0;
  padding: 80px 24px;
  justify-content: flex-start;
  align-items: center;
  background-color: ${theme.colors.white};
  transition: all 200ms ease-in-out;
  transform: translate(100%);
  visibility: hidden;
  ${({ active }) =>
    active &&
    `transform: translate(0,0); visibility: visible; display: flex;`}

  a {
    color: ${theme.colors.black};
    text-decoration: none;
    cursor: pointer;

    &.active {
      padding: 30px 0px;
      border-bottom: 3px solid ${theme.colors.orange};
    }
  }

  ${MediaHelper('mobile')`align-items: flex-start;`}
`

const WrapperContentHeader = styled.div`
  display: flex;
  flex-direction: row;

  ${MediaHelper('tablet')`flex-direction: row-reverse;`}
`

const WrapperMegaMenu = styled.div`
  top: 95px;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
`

const ShapeArrow = styled.div`
  width: 100px;
  height: 100px;
  position: absolute;
  overflow: hidden;
  top: 10px;
  box-shadow: 0 16px 10px -17px rgba(0, 0, 0, 0.5);

  :after {
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    background: ${theme.colors.white};
    transform: rotate(45deg);
    top: 75px;
    left: 25px;
    box-shadow: ${theme.boxShadow};
  }
`

const WrapperAccordion = styled.div`
  width: 100%;
  font-weight: bold;
  color: ${theme.colors.black};
  font-size: 14px;
`

const WrapperSolutionMobile = styled.div`
  position: relative;
  max-height: 56px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  :last-child {
    margin-bottom: unset;
  }
`

const RightChevron = styled(Chevron)`
  transform: rotate(270deg);
`

const DownChevron = styled(Chevron)`
  path {
    fill: ${({ color }) => color};
  }
`

const ContainerStack = styled.div`
  display: flex;
  position: fixed;
  padding: 15px 16px 0 24px;
  left: 0;
  flex-direction: column;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  background-color: ${theme.colors.white};
  z-index: ${theme.zIndex.modal};
  transition: all 200ms ease-in-out;
  transform: translate(100%);
  ${({ active }) => active && `transform: translate(0,0);`}
`

const ArrowLeft = styled(Arrow).attrs(() => ({
  style: {
    transform: 'scaleX(-1)',
  },
}))`
  path {
    fill: ${theme.colors.grayDark};
  }
`

const WrapperNavigationStack = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 32px;
`

const WrapperContentStack = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`

const ItemStackSolution = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`

const WrapperIcon = styled.div`
  display: flex;
  width: 32px;
  justify-content: center;
`

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${theme.colors.grayLight};
  margin-block: 16px;
`

const Wrapper = styled.div`
  width: 100%;
  gap: 8px;
`

const StyledFlex = styled(Flex)`
  width: 100%;
`
const STYLED_ICON = {
  width: '32px',
  height: '32px',
  objectFit: 'cover',
}

interface EnvironmentType {
  backgroundColor: string
  color: string
  boxShadow: string
  logoLight: boolean
}

export interface IMenuSolution {
  redirectUrl: string
  title: string
  items: ISolutionMenu[]
}

interface IBusinessSolutionHeader {
  isNotTransparent?: boolean
  position?: string
  activeTab?: string
  lang: string
  menus: IMenuSolution[]
  styleContainer?: CSSProperties
  extra?: JSX.Element | JSX.Element[]
}

function BusinessSolutionHeader({
  isNotTransparent = false,
  position = 'sticky',
  activeTab = null,
  lang,
  menus,
  styleContainer,
  extra,
}: IBusinessSolutionHeader): JSX.Element {
  const [open, setOpen] = useState(false)
  const [active, setActive] = useState(activeTab)
  const [onHover, setOnHover] = useState()
  const [stackMenu, setStackMenu] = useState()
  const [environment, setEnvironment] = useState<EnvironmentType>({
    backgroundColor: isNotTransparent
      ? theme.colors.white
      : 'transparent',
    color: isNotTransparent ? theme.colors.black : theme.colors.white,
    boxShadow: isNotTransparent
      ? '0 2px 10px 0 rgba(0,0,0,0.1)'
      : 'none',
    logoLight: position === 'fixed',
  })
  const headerMenu = transformData(menus)
  const tracker = eventTracking(trackPosthog())
  const router = useRouter()
  const { t } = useTranslation()

  const handleScroll = (): void => {
    const offset = window.scrollY
    if (offset > 10 && position !== 'absolute') {
      setEnvironment({
        backgroundColor: theme.colors.white,
        color: theme.colors.black,
        boxShadow: '0 2px 10px 0 rgba(0,0,0,0.1)',
        logoLight: false,
      })
    } else {
      setEnvironment({
        backgroundColor: isNotTransparent
          ? theme.colors.white
          : 'transparent',
        color: isNotTransparent
          ? theme.colors.black
          : theme.colors.white,
        boxShadow: isNotTransparent
          ? '0 2px 10px 0 rgba(0,0,0,0.1)'
          : 'none',
        logoLight: position === 'fixed',
      })
    }
  }

  const megaMenu = useMemo(() => {
    const result = menus?.filter((menu) => {
      const options = menu?.items?.find(
        (submenu) => submenu.title !== '',
      )
      if (!options) return null
      return { ...options, key: textToSnake(options?.title) }
    })

    return result
  }, [menus])

  const isKeyOnHover = useMemo(
    () => megaMenu?.map((item) => textToSnake(item.title)),
    [megaMenu],
  )

  const handleOpenBurgerMenu = useCallback(() => {
    setOpen(!open)
    setStackMenu(null)
    setActive(null)
  }, [open])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleClickMenu = useCallback(
    (item: { title: string; key: string }) => {
      tracker.track('Clicked', {
        Name: item.title,
      })
      setActive(item.key)
      setOpen(false)
    },
    [],
  )

  const handleStackMenu = useCallback((key) => setStackMenu(key), [])

  const resetHover = useCallback(() => setOnHover(null), [])

  const onMouseEnter = useCallback((key) => setOnHover(key), [])
  const onMouseLeave = useCallback(() => resetHover(), [])

  const renderExtraMenu = useCallback((props = {}) => {
    const {
      bottomDescription = t('DESCRIPTION_EXTRA_MENU', {
        ns: 'common',
      }),
      bottomIconUrl,
      bottomRedirectUrl = `${WEB_DESKTOP_RALALI_URL}/solutions`,
      bottomTitle = t('TITLE_EXTRA_MENU', { ns: 'common' }),
    } = props
    return (
      <Link href={bottomRedirectUrl}>
        <ItemStackSolution>
          <Flex gap="8px">
            {bottomIconUrl ? (
              <Image {...STYLED_ICON} src={bottomIconUrl} />
            ) : (
              <WrapperIcon>
                <RalaliIcon />
              </WrapperIcon>
            )}
            <Wrapper>
              <Typography type="bparagraph">{bottomTitle}</Typography>
              <Typography type="rlabel" color="grayDark">
                {bottomDescription}
              </Typography>
            </Wrapper>
          </Flex>
          <WrapperIcon>
            <RightChevron />
          </WrapperIcon>
        </ItemStackSolution>
      </Link>
    )
  }, [])

  const renderExtraData = useMemo(() => {
    let heading: string
    let stackname: string
    let extraData
    const submenu = headerMenu
      .find((menu) => {
        heading = menu.title
        return textToSnake(menu.title) === active
      })
      ?.items.find((item) => {
        stackname = item.title
        extraData = item
        return textToSnake(item.title) === stackMenu
      })?.items
    return { submenu, heading, stackname, extraData }
  }, [active, stackMenu])

  const renderStackMenu = useMemo(() => {
    const { submenu, heading, stackname, extraData } = renderExtraData

    return (
      <ContainerStack active={stackMenu}>
        <WrapperNavigationStack onClick={() => setStackMenu(null)}>
          <ArrowLeft />
          <Typography type="bsubtitle">{heading}</Typography>
        </WrapperNavigationStack>
        <WrapperContentStack>
          <Typography type="bsubtitle" marginBottom="24px">
            {stackname}
          </Typography>
          {submenu?.map((solution) => (
            <Link
              href={solution.redirectUrl}
              key={textToSnake(solution.title)}
            >
              <ItemStackSolution>
                <Flex gap="8px">
                  <Image {...STYLED_ICON} src={solution.iconUrl} />
                  <Flex flexDirection="column" gap="4px">
                    <Typography type="bparagraph">
                      {solution.title}
                    </Typography>
                    <Typography type="rlabel" color="grayDark">
                      {solution.description}
                    </Typography>
                    <Typography type="bcaption" color="redOrange">
                      {solution.redirectText}
                    </Typography>
                  </Flex>
                </Flex>
              </ItemStackSolution>
            </Link>
          ))}
          <Divider />
          {extraData && renderExtraMenu(extraData)}
        </WrapperContentStack>
      </ContainerStack>
    )
  }, [stackMenu, active])

  const renderMenuMobile = useCallback(
    ({ title, items, redirectUrl }) => {
      const key = textToSnake(title)
      const accordionMenu = isKeyOnHover.includes(key)
      const handleClickAccordion = () => {
        if (active !== key) {
          return setActive(key)
        }
        return setActive(null)
      }

      if (accordionMenu) {
        return (
          <WrapperAccordion>
            <Accordion
              onChange={handleClickAccordion}
              expanded={active === key}
              paddingSummary="0"
              summaryStyle={{
                padding: '0px',
                width: '100%',
                'min-height': 'unset',
              }}
              styleDetails={{
                padding: '24px 0 0 16px',
              }}
              noStyleWrapper
              summary={title}
              borderBottomExpanded="0"
            >
              {items.map((item) => {
                const keySolution = textToSnake(item.title)
                return (
                  <WrapperSolutionMobile
                    key={keySolution}
                    onClick={() => handleStackMenu(keySolution)}
                  >
                    <Flex gap="8px" alignItems="center">
                      <Image {...STYLED_ICON} src={item.iconUrl} />
                      <Flex alignItems="center">
                        <Typography type="bparagraph">
                          {item.title}
                        </Typography>
                        <Typography type="rlabel" color="grayDark">
                          {item.description}
                        </Typography>
                      </Flex>
                    </Flex>
                    <RightChevron />
                  </WrapperSolutionMobile>
                )
              })}
              <Divider />
              {renderExtraMenu()}
            </Accordion>
          </WrapperAccordion>
        )
      }
      return (
        <StyledLink
          onClick={() => handleClickMenu({ title, key })}
          href={accordionMenu ? null : redirectUrl}
          data-testid={`link-${key}`}
        >
          {title}
        </StyledLink>
      )
    },
    [stackMenu, active],
  )

  const renderMainMenu = useMemo(() => {
    return headerMenu?.map((item) => {
      const key = textToSnake(item.title)
      const activeMega = isKeyOnHover.includes(key)
      let renderLink
      if (!open) {
        renderLink = (
          <StyledLink
            active={!activeMega && active === key}
            onClick={() => handleClickMenu({ ...item, key })}
            href={activeMega ? null : item.redirectUrl}
            data-testid={`link-${key}`}
            onMouseEnter={() => onMouseEnter(key)}
            color={environment.color}
          >
            {item.title}
            {activeMega && <DownChevron color={environment.color} />}
          </StyledLink>
        )
      }
      if (open) {
        renderLink = renderMenuMobile({ ...item })
      }
      return (
        <WrapperMenu vertical={open} key={key}>
          {renderLink}
          {onHover === key && activeMega && !open && (
            <>
              <ShapeArrow />
              <WrapperMegaMenu active={onHover}>
                <MegaMenuComp
                  items={item.items}
                  onMouseLeave={onMouseLeave}
                />
              </WrapperMegaMenu>
            </>
          )}
        </WrapperMenu>
      )
    })
  }, [
    open,
    menus,
    onHover,
    isKeyOnHover,
    stackMenu,
    active,
    environment,
  ])

  const isLogin = isClientAuthorized()
  const { profileData, isLoading } = useStoreState(
    (state) => state.profile,
  )

  const renderExtraButton = useMemo(() => {
    return (
      <AuthButton
        isLoading={isLoading}
        isLogin={isLogin}
        name={profileData?.name}
        image={profileData?.profile_picture}
        color={environment.color}
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin, profileData, environment, isLoading])

  return (
    <Overlay
      isActive={isKeyOnHover.includes(onHover)}
      onOverlayClick={resetHover}
    >
      <Header
        justifyContent="space-between"
        backgroundColor={environment.backgroundColor}
        boxShadow={environment.boxShadow}
        color={environment.color}
        data-testid="header-business-solution"
        position={position}
      >
        <Container
          styleContainer={styleContainer}
          justifyContent="space-between"
        >
          <WrapperLogo
            isNotTransparent={isNotTransparent}
            openMenu={open}
            logoLight={environment.logoLight}
            href={`${WEB_DESKTOP_RALALI_URL}`}
          >
            <BusinessSolustionHeaderLogo />
          </WrapperLogo>
          <WrapperContentHeader>
            <Menu data-testid="menu" color={environment.color}>
              {renderMainMenu}
              {extra || (
                <>
                  {renderExtraButton}
                  <LanguageDropdown
                    initialLang={lang}
                    color={environment.color}
                    onChange={router.reload}
                  />
                </>
              )}
            </Menu>
            <Burger
              setOpen={handleOpenBurgerMenu}
              open={open}
              iconLight={environment.logoLight}
              isNotTransparent={isNotTransparent}
            />
          </WrapperContentHeader>
        </Container>
        <MenuMobile active={open}>
          {renderMainMenu}
          <LanguageAccordion
            initialLang={lang}
            onChange={router.reload}
          />
          <StyledFlex margin="16px 0 0 0">
            <AuthButton
              isLogin={isLogin}
              width="100%"
              name={profileData?.name}
              image={profileData?.profile_picture}
            />
          </StyledFlex>
          {renderStackMenu}
        </MenuMobile>
      </Header>
    </Overlay>
  )
}

export default BusinessSolutionHeader
